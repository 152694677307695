<script>
import { mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  data() {
    return {
      socketQsl: true,
      cedroQsl: true,
      qslInProgress: false,
    }
  },
  computed: {
    ...mapFields(['initialized', 'socketWentRogue', 'socketReconnectionAttempts', 'cedroWentRogue', 'cedroReconnectionAttempts']),
    ...mapFields('trades', ['subscribedSymbols']),
    ...mapFields('portfolios', ['portfoliosLoaded']),
    ...mapFields('trades', ['portfolioTrades']),
  },
  beforeMount() {
    if (this.initialized) return
    this.initialized = true
    this.portfoliosLoaded = true
    window.addEventListener('focus', this.handleFocus)
    this.$socket.open()
    this.$socket.on('force-refresh', (time) => setTimeout(() => location.reload(), Math.random() * (time || 30000)))
    this.$socket.on('hi', () => this.$socket.emit('hi'))
    this.$socket.on('qsl', () => (this.socketQsl = true))
    this.$socket.on('connect', () => {
      this.$axios.get('/version').then((res) => {
        if (res.data !== this.$config.commitHash) setTimeout(() => location.reload(), Math.random() * 30000)
      })
      this.socketReconnectionAttempts = 0
      this.socketWentRogue = false
      this.socketQsl = true
    })
    this.$socket.on('disconnect', (reason) => {
      this.socketReconnection()
    })
    this.$socket.on('trades', (data) => {
      this.receiveTrades(data)
    })
    this.$socket.on('portfolios', (data) => {
      this.receivePortfolios(data)
    })
    this.$cedro.open()
    this.$cedro.on('qsl', () => (this.cedroQsl = true))
    this.$cedro.on('connect', () => {
      this.cedroReconnectionAttempts = 0
      this.cedroWentRogue = false
      this.cedroQsl = true
      if (this.$auth.loggedIn && !this.$auth.user.hats.guest) this.$cedro.emit('i-am-user')
      Object.keys(this.subscribedSymbols || {}).forEach((symbol) => this.$cedro.emit('subscribe', { symbol }))
    })
    this.$cedro.on('disconnect', (reason) => {
      this.cedroReconnection()
    })
    this.$cedro.on('live-quote', (quote) => this.queueQuote(quote))
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.handleFocus)
    this.$socket.removeAllListeners()
    this.$socket.disconnect()
    this.$cedro.removeAllListeners()
    this.$cedro.disconnect()
  },
  methods: {
    ...mapMutations({
      receivePortfolios: 'portfolios/receivePortfolios',
      receiveTrades: 'trades/receiveTrades',
      queueQuote: 'trades/queueQuote',
    }),
    socketReconnection() {
      return setTimeout(() => {
        if (this.$socket.connected) return
        if (this.socketWentRogue) return
        if (this.socketReconnectionAttempts >= 5) return (this.socketWentRogue = true)
        this.socketReconnectionAttempts++
        this.$socket.connect()
        setTimeout(() => this.socketReconnection(), 2500)
      }, 500 * this.socketReconnectionAttempts + Math.random() * 1000)
    },
    cedroReconnection() {
      return setTimeout(() => {
        if (this.$cedro.connected) return
        if (this.cedroWentRogue) return
        if (this.cedroReconnectionAttempts >= 5) return (this.cedroWentRogue = true)
        this.cedroReconnectionAttempts++
        this.$cedro.connect()
        setTimeout(() => this.cedroReconnection(), 2500)
      }, 500 * this.cedroReconnectionAttempts + Math.random() * 1000)
    },
    handleFocus() {
      if (this.qslInProgress) return
      this.socketQsl = false
      this.cedroQsl = false
      this.qslInProgress = true
      this.$socket.emit('qsl')
      this.$cedro.emit('qsl')
      setTimeout(() => {
        this.qslInProgress = false
        if (!this.socketQsl && this.$socket.connected) this.$socket.disconnect()
        if (!this.cedroQsl && this.$cedro.connected) this.$cedro.disconnect()
      }, 5000)
    },
  },
}
</script>
