<template>
  <LoadingScreen v-if="$route.query._id || !homeVersion" />
  <div v-else-if="!checkout">
    <HeaderA :has-custom-content="hasCustomContent" />
    <!-- <HeaderB v-if="homeVersion === 'B'" :has-custom-content="hasCustomContent" /> -->
    <HomeFromsCustomContent v-if="hasCustomContent" :searched-asset="searchedAsset" />
    <HomeAThreeSteps />
    <!-- <HomeBThreeSteps v-if="homeVersion === 'B'" /> -->
    <AdditionalInfo />
    <!-- <Selector v-if="homeVersion === 'A'" /> -->
    <Subscription id="subscription-section" :theme="homeVersion === 'A' ? 'dark-mode' : ''" />
    <Analysts />
    <MediaSection />
    <Footer :mobile-footer="true" :show-analysts="false" />
    {{ pushData }}
  </div>
  <Checkout v-else :number-from-home="mobileNumber" :recent-otp-timestamp="recentOtp" />
</template>

<script>
// import { PushNotifications } from '@capacitor/push-notifications'
import { gsap, TweenLite, Power2 } from 'gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import { mapGetters } from 'vuex'
import homeSocketEventHandler from '@/components/mixins/homeSocketEventHandler'
gsap.registerPlugin(ScrollToPlugin)

export default {
  components: {
    LoadingScreen: () => import('@/components/UI/LoadingScreen'),
    HeaderA: () => import('@/components/home/HomeHeaderA'),
    // HeaderB: () => import('@/components/home/HomeHeaderB'),
    HomeFromsCustomContent: () => import('@/components/home/HomeFromsCustomContent'),
    HomeAThreeSteps: () => import('@/components/home/HomeAThreeSteps'),
    // HomeBThreeSteps: () => import('@/components/home/HomeBThreeSteps'),
    AdditionalInfo: () => import('@/components/home/AdditionalInfo'),
    // Selector: () => import('@/components/home/HomePortfolioSelector'),
    Subscription: () => import('@/components/home/HomeSubscription'),
    MediaSection: () => import('@/components/home/HomeMedia'),
    Analysts: () => import('@/components/home/HomeAnalysts'),
    Footer: () => import('@/components/Footer'),
    Checkout: () => import('@/pages/checkout'),
  },
  mixins: [homeSocketEventHandler],
  layout: 'home',
  middleware: ['redirectAuthUser', 'phoneOrEmailAb', 'handleBackUrl'],
  data() {
    return {
      checkout: false,
      recentOtp: null,
      mobileNumber: '',
      searchedAsset: '',
      showPhoneInput: false,
      from: this.$cookies.get('voucher'),
      homeVersion: this.$cookies.get('homeVersion'),
      pushData: '',
    }
  },
  computed: {
    ...mapGetters('voucherData', ['homeVoucherList']),

    hasCustomContent() {
      return (!!this.from && this.homeVoucherList.includes(this.from)) || this.searchedAssetHasOpenTrade()
    },
    openTrades() {
      const portfolioIds = this.portfolioTrades && Object.keys(this.portfolioTrades)
      if (!portfolioIds) return []
      return portfolioIds.reduce((acc, p) => {
        const trades = this.portfolioTrades[p].open
        for (const trade in trades) {
          if (!acc.includes(trades[trade].symbol)) acc.push(trades[trade].symbol)
        }
        return acc
      }, [])
    },
  },

  async beforeMount() {
    // window.addEventListener('resize', this.handleResize)
    if (this.$route.query._id) {
      if (this.$auth.loggedIn && this.$auth.user._id !== this.$route.query._id) await this.$axios.get('/api/auth/logout')
      if (this.$auth.loggedIn && this.$auth.user.auth.validWhatsapp && this.$auth.user._id === this.$route.query._id)
        return window.location.replace('/carteiras')
      await this.$axios.get('/api/auth/magic-link?_id=' + this.$route.query._id)
      window.location.replace('/checkout?backUrl=%2Fcdiraiz#atualizar-cadastro')
    }
  },
  mounted() {
    this.$nuxt.$on('go-to-signup', (target = 'subscription-wrapper') => {
      // if (this.$auth.loggedIn) return this.$router.push({ path: '/carteiras' })
      if (this.$auth.loggedIn) return window.location.replace('/carteiras')
      const scrollTarget = target === 'login' ? 'subscription-wrapper' : target
      const scrollTo = `#${scrollTarget}`
      TweenLite.to(window, 1, {
        scrollTo: { y: scrollTo },
        ease: Power2.easeInOut,
        onComplete: () => {
          if (scrollTo !== '#subscription-wrapper') return
          this.$nuxt.$emit('focus-whatsapp-input')
        },
      })
    })
  },
  created() {
    this.$cookies.remove('userNumber')
    this.$cookies.remove('nameAndEmail')
    this.$nuxt.$on('go-to-checkout', (mobileNumber) => {
      this.checkout = true
      this.mobileNumber = mobileNumber
    })
    this.$nuxt.$on('back-to-home', (mobileNumber) => {
      this.checkout = false
      this.mobileNumber = ''
    })
    this.$nuxt.$on('recent-otp', (recentOtp) => {
      this.recentOtp = new Date(recentOtp)
    })

    // PushNotifications.requestPermissions().then((result) => {
    //   this.pushData += `\n1 - ${JSON.stringify(result)}`
    //   if (result.receive === 'granted') {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register()
    //   } else {
    //     // Show some error
    //   }
    // })

    // PushNotifications.addListener('registration', (token) => {
    //   this.pushData += `\n2- ${JSON.stringify(token)}`
    // })

    // PushNotifications.addListener('registrationError', (error) => {
    //   this.pushData += `\n3- ${JSON.stringify(error)}`
    // })

    // PushNotifications.addListener('pushNotificationReceived', (notification) => {
    //   this.pushData += `\n4- ${JSON.stringify(notification)}`
    // })

    // PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    //   this.pushData += `\n5- ${JSON.stringify(notification)}`
    // })
  },
  beforeDestroy() {
    this.portfoliosLoaded = false
    // window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    gadsSearchedAsset() {
      const lastFrom = (this.$cookies.get('lastFrom') || '').toString()
      const searchedAsset = lastFrom?.replace('gads-search-recomendacoes-ativas-', '')
      if (searchedAsset) this.searchedAsset = searchedAsset.toUpperCase()
      return lastFrom?.includes('gads-search-recomendacoes-ativas')
    },
    searchedAssetHasOpenTrade() {
      if (!this.gadsSearchedAsset()) return false
      return this.openTrades.includes(this.searchedAsset)
    },
    // handleResize: debounce(function () {
    //   this.$nuxt.$emit('is-mobile', window.innerWidth <= 992)
    // }, 150),
  },
}
</script>
<style lang="scss">
body,
html {
  font-family: $cdi-font-headings;
}
.sections-container {
  color: $cdi-blue-alt-dark !important;
  section {
    &:nth-of-type(odd) {
      background-color: $cdi-blue-alt-light;
    }
  }
}
.swiper-pagination-bullet-active {
  background-color: $cdi-primary-accent;
}
.home-c-container {
  background: linear-gradient(to bottom, $cdi-primary-blue, #4d3b67);
  background-size: cover;
  background-position: center;
}
</style>
